<template>
  <div>
    <h2 class="th-title">Trending</h2>
    <a-card>
      <div class="d-flex justify-content-between mb-3">
        <a-input
          style="width: 200px"
          v-model:value="search"
          @change="handleSearchBanner"
          placeholder="Cari berdasarkan akun"
        >
          <template #prefix>
            <span>
              <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
            </span>
          </template>
        </a-input>
        <!-- <a-button @click.prevent="showModalAdd">
          <template #icon><plus-outlined /></template>
          Add Report
        </a-button> -->
      </div>
      <div>
        <a-table :class="$style.table" :columns="columns" :data-source="data" bordered>
          <template #account="{ text, record }">
            <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.key } }">{{text}}</router-link>
          </template>
          <template #post="{ record }">
            <router-link class="text-info kit__utils__link" :to="{ name: 'Detail Post Akun', params: { id: record.key, post: record.post } }">See Post Detail - 2022/07/01</router-link>
          </template>
          <template #customWeight="{ column, text, record }">
            <a-input
              v-if="editableData[record.key]"
              v-model:value="editableData[record.key][column.dataIndex]"
              style="margin: -5px 0"
            />
            <span v-else>
              {{ text }}
            </span>
          </template>
          <template #action="{record}">
            <span>
              <!-- <a-popover>
                <template #content class="kit__utils__link font-size-16 text-success" >Setujui Akun</template>
                <a-popconfirm
                  title="Yakin ingin menyetujui data ini?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="setujuiAkun(record)"
                >
                  <button type="button" class="btn btn-outline-success">
                    <i class="fe fe-thumbs-up" aria-hidden="true"></i>
                  </button>
                </a-popconfirm>
              </a-popover> -->
              <span v-if="editableData[record.key]">
                <a @click="save(record.key)" class="ml-1 text-success font-size-24">
                  <i class="fe fe-check" aria-hidden="true"></i>
                </a>
                <a-popconfirm title="Sure to cancel?" @confirm="cancel(record.key)">
                  <a class="ml-1 text-danger font-size-24">
                    <i class="fe fe-x" aria-hidden="true"></i>
                  </a>
                </a-popconfirm>
              </span>
              <a-popover v-else>
                <template #content>Modify Weight</template>
                  <button @click="edit(record.key)" type="button" class="btn btn-outline-info ml-1">
                    <i class="fe fe-edit" aria-hidden="true"></i> Modify Weight
                  </button>
              </a-popover>
            </span>
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRaw } from 'vue';
import { useStore } from 'vuex';
import { cloneDeep } from 'lodash-es';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { useForm } from '@ant-design-vue/use';
// import axios from 'axios'
import apiClient from '@/services/axios'

const columns = [
  {
    title: 'Position',
    align: 'center',
    width: 50,
    dataIndex: 'position',
    key: 'position',
    slots: { customRender: 'position' },
  },
  {
    title: 'Post',
    align: 'center',
    width: 200,
    dataIndex: 'post',
    key: 'post',
    slots: { customRender: 'post' },
  },
  {
    title: 'Akun',
    align: 'center',
    width: 100,
    dataIndex: 'account',
    key: 'account',
    slots: { customRender: 'account' },
  },
  {
    title: 'Likes',
    align: 'center',
    width: 200,
    dataIndex: 'likes',
    key: 'likes',
    slots: { customRender: 'likes' },
  },
  {
    title: 'Shares',
    align: 'center',
    width: 200,
    dataIndex: 'shares',
    key: 'share',
    slots: { customRender: 'share' },
  },
  {
    title: 'Comments',
    align: 'center',
    width: 200,
    dataIndex: 'comments',
    key: 'comments',
    slots: { customRender: 'comments' },
  },
  {
    title: 'Date Weight',
    align: 'center',
    width: 200,
    dataIndex: 'dateWeight',
    key: 'dateWeight',
    slots: { customRender: 'dateWeight' },
  },
  {
    title: 'Tanggal Post Di Buat',
    align: 'center',
    width: 200,
    dataIndex: 'createdAt',
    key: 'createdAt',
    slots: { customRender: 'createdAt' },
  },
  {
    title: 'Total Weight',
    align: 'center',
    width: 200,
    dataIndex: 'weight',
    key: 'weight',
    slots: { customRender: 'weight' },
  },
  {
    title: 'Custom Weight',
    align: 'center',
    width: 200,
    dataIndex: 'customWeight',
    key: 'customWeight',
    slots: { customRender: 'customWeight' },
  },
  {
    title: 'Aksi',
    align: 'center',
    width: 200,
    // fixed: 'right',
    key: 'action',
    slots: { customRender: 'action' },
    align: 'center',
  },
];

export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
  },
  setup() {
    const store = useStore()
    let data = ref([])
    let weight = ref([])
    let search = ref(null)
    let loadingTable = ref(false)
    let backendUrl = ref(process.env.VUE_APP_BACKEND_URL)
    let backendUrlSlice = ref(null)
    const loadingAdd = ref(false)
    let idEdit = ref(null)

    const editableData = reactive({});
    const edit = (key) => {
      editableData[key] = cloneDeep(weight.value.filter(item => key === item.key)[0]);
      console.log(editableData[key], "key")
    };
    const save = (key) => {
      Object.assign(weight.value.filter(item => key === item.key)[0], editableData[key]);
      delete editableData[key];
    };
    const cancel = (key) => {
      delete editableData[key];
    };

    onMounted(async () => {
      fetchTrendings()
      sliceUrl()
    })

    const fetchTrendings = async (search = '') => {
      // loadingTable.value = true
      // const rawData = await store.dispatch('banner/FETCH_BANNER', search)
      // // console.log('fetchBanner rawData => ', rawData)
      // loadingTable.value = false
      // data.value = rawData.map(row => {
      //   return {
      //     key: row._id,
      //     name: row.name,
      //     description: row.description,
      //     image: row.image,
      //     url: row.url,
      //     isActive: row.isActive,
      //   }
      // })
      data.value = store.state.akun.trendings
      weight.value = store.state.akun.trendings
    }

    const sliceUrl = () => {
      const url = backendUrl.value
      backendUrlSlice.value = url.slice(0, -1)
      console.log(backendUrlSlice.value)
    }


    return {
      data,
      columns,
      loadingTable,
      search,
      loadingAdd,
      idEdit,
      backendUrl,
      backendUrlSlice,
      editableData,
      edit,
      save,
      cancel,
    }
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>